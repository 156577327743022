<template>
  <div class="bg " :class="video ? 'bg24' : 'bg1'">
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
      <img :src="imgUrl" alt="" v-if="!video">
      <div class="video"    v-else>
        <video controls

               :src="imgUrl"
        >
          您的浏览器不支持Video标签
        </video>
      </div>
    </div>

  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";

export default {
  name: "seventeenPage",
  components: {ClassBut},
  data() {
    return {
      imgUrl: [],
      index:0,
      isSkip:false,
      video:false,
    }
  },
  created() {
    this.gitApicourseTalk()
  },
  methods: {
    forward() {
      if ( this.index+1 < this.imgUrl1.length ) {
        this.index++
        this.imgUrl =  this.imgUrl1[this.index].imgUrl
        this.video =  this.imgUrl1[this.index].video
        // console.log(this.imgUrl1[this.index].imgUrl)
      }else {
        //特色课程
        if ( this.isSkip ) {
          this.$router.push({
            name: 'semesterHighlights',
            query: this.$route.query,
          })
        }else {
          this.$router.push({
            name: 'videoPresentation1',
            query: this.$route.query,
          })
        }
      }

    },
    backOfff(){
      if ( this.index > 0 ) {
        this.index--
        this.imgUrl =  this.imgUrl1[this.index].imgUrl
        this.video =  this.imgUrl1[this.index].video
      }else {
        this.$router.back(-1)
      }
    },
    async gitApicourseTalk() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/searchExpandCourse`, {
        classId: this.$route.query.classId
      })

      if ( res.length > 1 ) {
        let r = await service.get(`/dwart/an_teaching/hbk/v1/getAdvVideo?classId=${this.$route.query.classId}&type=2`);
        if ( r.url ) {
          const newData = {
            "imgUrl": r.url,
            "_show": true,
            video:true,
          };
          res.splice(1, 0, newData);
          this.isSkip = true
        }
      }
      this.imgUrl = res[0].imgUrl
      this.imgUrl1 = res

    },
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
.class--con{
  text-align: center;
  img{
    height: 80%;
    object-fit: contain;
    margin-top: 100px;
    width: 80%;
  }
  .video {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 1349px;
      height: 778px;
      border: 20px solid #ffe16c;
      border-radius: 20px;
    }
  }
}
</style>
